import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import USBDatePicker from '@usb-shield/react-date-picker';
import down from '../assets/img/down.png';

import { PermissionRoleContext } from '../../components/Hooks/usePermissionRoles';
import { defaultCalendarSettings } from '@usb-shield/react-calendar';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';
import { Checkbox } from 'primereact/checkbox';
import ReactDOM from 'react-dom';
import {
  USBGrid,
  USBColumn,
  USBButton,
  USBModal,
  USBModalHeader,
  USBModalBody,
  USBModalFooter,
  USBModalCloseIcon,
  USBPagination,
  USBSelect,
} from '@usb-shield/components-react';
import USBTable from '@usb-inner-src/react-table';
import moment from 'moment';
// import Router from 'next/router'
import { useHistory } from 'react-router-dom';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import { setUserRole, getQS } from '../../components/utilities/Utils';
import Loader from '../../components/utilities/Loader';
import { DashboardContext } from '../context/DashboardContext';
import { DocContext } from '../context/DocContext';
import {ExportToExcel} from '../../components/utilities/ExportToExcel';
import './ReportingDashboard.css';
import actionlogo from '../../assets/images/rightArrow.png';
import dateAlertIcon from '../../../assets/img/dateAlert.png';
import portalIndicator from '../../../assets/img/portal.png';
import searchIcon from '../../../assets/img/search.png';
import useUserRole from '../../components/Hooks/use-UserRole';

export const reuseFilterFn = (reportResultsFull, activeFilters) => {
  let initialData = reportResultsFull;
  if (activeFilters.reportingEntity !== '') {
    initialData = initialData.filter(
      (obj) => obj.metadata.reportingEntity === activeFilters.reportingEntity
    );
  }
  if (activeFilters.parentCompany !== '') {
    initialData = initialData.filter(
      (obj) => obj.metadata.parentCompanyName === activeFilters.parentCompany
    );
  }

  if (activeFilters.asOfDate !== '') {
    initialData = initialData.filter(
      (obj) => obj.asOfDate == activeFilters?.asOfDate
    );
  }
  console.log(activeFilters.cbDate);
  if (activeFilters.cbDate !== '') {
    
    initialData = initialData.filter(
      (obj) => obj.closingBinderDate && moment(obj.closingBinderDate).format('MM/DD/YYYY') == activeFilters?.cbDate
    );
  }

  if (activeFilters.submittedDate !== '') {
    initialData = initialData.filter(
      (obj) =>
      obj.submittedDate && moment(obj.submittedDate).format('MM/DD/YYYY') === activeFilters?.submittedDate
    );
  }

  if (activeFilters.requirementName !== '') {
    initialData = initialData.filter(
      (obj) => obj.category === activeFilters.requirementName
    );
  }
  if (activeFilters.dealName !== '') {
    initialData = initialData.filter(
      (obj) => obj.metadata.dealName === activeFilters.dealName
    );
  }
  if (activeFilters.status !== '' && activeFilters.status !== 'Status') {
    initialData = initialData.filter(
      (obj) => obj.status === activeFilters.status
    );
  }
  if (activeFilters.following !== '' && activeFilters.following !== 'Follow') {
    const followvalue = activeFilters.following == 'true' ? true : false;
    initialData = initialData.filter((obj) => obj.following === followvalue);
  }
  if (activeFilters.syndicationFundName !== '') {
    initialData = initialData.filter(
      (obj) =>
        obj.metadata.syndicationFundName === activeFilters.syndicationFundName
    );
  }
  // Data after selecting parent company
  return initialData;
};

export const globalSearchFn = (value, results,dashboardData) => {
  let temp = [];
  if (value !== '') {
    results.forEach((item) => {
      if (
        (
          item.dueDate !== null &&
          item.dueDate !== '' &&
          item?.dueDate?.includes(value) ) ||
        (
          item.category !== null &&
          item.category !== '' &&
          item?.category?.toLowerCase().includes(value.toLowerCase()) ) ||
        (
          item.metadata.reportingEntity !== null &&
          item.metadata.reportingEntity !== '' &&
          item?.metadata?.reportingEntity.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.metadata.dealName !== null &&
          item.metadata.dealName !== '' &&
          item?.metadata?.dealName.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.metadata.parentCompanyName !== null &&
          item.metadata.parentCompanyName !== '' &&
          item?.metadata?.parentCompanyName.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.asOfDate !== null &&
          item.asOfDate !== '' &&
          item?.asOfDate.includes(value)) ||
        (
          item.closingBinderDate !== null &&
          item.closingBinderDate !== '' &&
          item?.closingBinderDate.includes(value)) ||
        ((dashboardData.status=="Submitted") &&
          item.submittedDate !== null &&
          item.submittedDate !== '' &&
          item?.submittedDate.includes(value)) ||
        (
          item.status !== null &&
          item.status !== '' &&
          item?.status.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.metadata.syndicationFundName !== null &&
          item.metadata.syndicationFundName !== '' &&
          item?.metadata?.syndicationFundName.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.metadata.dealId !== null &&
          item.metadata.dealId &&
          item.metadata.dealId !== '' &&
          item?.metadata?.dealId.toLowerCase().includes(value.toLowerCase()))
      ) {
        temp.push(item);
      }
    });
  } else {
    temp = results;
  }
  // console.log(temp, 'search');
  return temp;
};

export const reuseSearchFn = (value, searchValue, results) => {
  let temp = [];
  if (value !== '') {
    results.forEach((item) => {
      if (
        ((searchValue === 'documentType' || searchValue === '') &&
          item.category !== null &&
          item.category !== '' &&
          item.category.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'legalEntity' || searchValue === '') &&
          item.metadata.reportingEntity !== null &&
          item.metadata.reportingEntity !== '' &&
          item.metadata.reportingEntity
            .toLowerCase()
            .indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'dealName' || searchValue === '') &&
          item.metadata.dealName !== null &&
          item.metadata.dealName !== '' &&
          item.metadata.dealName.toLowerCase().indexOf(value.toLowerCase()) >
            -1) ||
        ((searchValue === 'parentCompany' || searchValue === '') &&
          item.metadata.parentCompanyName !== null &&
          item.metadata.parentCompanyName !== '' &&
          item.metadata.parentCompanyName
            .toLowerCase()
            .indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'asOfDate' || searchValue === '') &&
          item.asOfDate !== null &&
          item.asOfDate !== '' &&
          item.asOfDate==value) ||
        ((searchValue === 'cbDate' || searchValue === '') &&
          item.closingBinderDate !== null &&
          item.closingBinderDate !== '' &&
          moment(item.closingBinderDate).format('MM/DD/YYYY')==value) ||
        ((searchValue === 'submittedDate' || searchValue === '') &&
          item.submittedDate !== null &&
          item.submittedDate !== '' &&
          moment(item.submittedDate).format('MM/DD/YYYY')==value) ||
        ((searchValue === 'status' || searchValue === '') &&
          item.status !== null &&
          item.status !== '' &&
          item.status.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'syndicationFundName' || searchValue === '') &&
          item.metadata.syndicationFundName !== null &&
          item.metadata.syndicationFundName !== '' &&
          item.metadata.syndicationFundName
            .toLowerCase()
            .indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'dealNumber' || searchValue === '') &&
          item.metadata.dealId !== null &&
          item.metadata.dealId &&
          item.metadata.dealId !== '' &&
          item.metadata.dealId.startsWith(value))
      ) {
        temp.push(item);
      }
    });
  } else {
    temp = results;
  }
  // console.log(temp, 'search');
  return temp;
};

export const reuseSortingFn = (sortValue, results) => {
  let tmpResults = results;
  if (sortValue === 'fundDesc') {
    tmpResults.sort((a, b) => {
      const nameA =
        a.metadata.syndicationFundName !== null
          ? a.metadata.syndicationFundName.toLowerCase()
          : '';
      const nameB =
        b.metadata.syndicationFundName !== null
          ? b.metadata.syndicationFundName.toLowerCase()
          : '';
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'fundAsc') {
    const emptyResults = results.filter((n) => {
      return (
        n.metadata.syndicationFundName === null ||
        n.metadata.syndicationFundName === ''
      );
    });
    const nonEmptyResults = results.filter((n) => {
      return (
        n.metadata.syndicationFundName !== null &&
        n.metadata.syndicationFundName !== ''
      );
    });
    nonEmptyResults.sort((a, b) => {
      const nameA =
        a.metadata.syndicationFundName !== null
          ? a.metadata.syndicationFundName.toLowerCase()
          : '';
      const nameB =
        b.metadata.syndicationFundName !== null
          ? b.metadata.syndicationFundName.toLowerCase()
          : '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    tmpResults = [...nonEmptyResults, ...emptyResults];
  }
  if (sortValue === 'asOfDateDesc') {
    const nonAsOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'asOfDate';
    });
    const asOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() === 'asOfDate';
    });
    asOfDateResults.sort((a, b) => {
      const key1 = new Date(a.asofDate);
      const key2 = new Date(b.asofDate);
      if (key1 > key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...asOfDateResults, ...nonAsOfDateResults];
  }

  if (sortValue === 'asOfDateAsc') {
    const nonAsOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'asofDate';
    });
    const asOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() === 'asofDate';
    });
    asOfDateResults.sort((a, b) => {
      const key1 = new Date(a.asofDate);
      const key2 = new Date(b.asofDate);
      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...asOfDateResults, ...nonAsOfDateResults];
  }
  if (sortValue === 'cbDateDesc') {
    const nonAsOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'cbDate';
    });
    const asOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() === 'cbDate';
    });
    asOfDateResults.sort((a, b) => {
      const key1 = new Date(a.closingBinderDate);
      const key2 = new Date(b.closingBinderDate);
      if (key1 > key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...asOfDateResults, ...nonAsOfDateResults];
  }

  if (sortValue === 'cbDateAsc') {
    const nonAsOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'cbDate';
    });
    const asOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() === 'cbDate';
    });
    asOfDateResults.sort((a, b) => {
      const key1 = new Date(a.closingBinderDate);
      const key2 = new Date(b.closingBinderDate);
      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...asOfDateResults, ...nonAsOfDateResults];
  }

  if (sortValue === 'submittedDesc') {
    const nonSubmittedResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'submitted';
    });
    const submittedResults = results.filter((n) => {
      return n.status.toLowerCase() === 'submitted';
    });
    submittedResults.sort((a, b) => {
      const key1 = new Date(a.submittedDate);
      const key2 = new Date(b.submittedDate);
      if (key1 > key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...submittedResults, ...nonSubmittedResults];
  }
  if (sortValue === 'submittedAsc') {
    const nonSubmittedResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'submitted';
    });
    const submittedResults = results.filter((n) => {
      return n.status.toLowerCase() === 'submitted';
    });
    submittedResults.sort((a, b) => {
      const key1 = new Date(a.submittedDate);
      const key2 = new Date(b.submittedDate);
      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...submittedResults, ...nonSubmittedResults];
  }

  if (sortValue === 'dateDesc') {
    tmpResults = results.sort((a, b) => {
      const key1 = new Date(a.dueDate);
      const key2 = new Date(b.dueDate);
      if (key1 > key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
  }
  if (sortValue === 'dateAsc') {
    tmpResults = results.sort((a, b) => {
      const key1 = new Date(a.dueDate);
      const key2 = new Date(b.dueDate);
      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
  }
  if (sortValue === 'dealDesc') {
    tmpResults.sort((a, b) => {
      const nameA = a.metadata.dealName.toLowerCase();
      const nameB = b.metadata.dealName.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'dealAsc') {
    tmpResults.sort((a, b) => {
      const nameA = a.metadata.dealName.toLowerCase();
      const nameB = b.metadata.dealName.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'legalDesc') {
    tmpResults.sort((a, b) => {
      const nameA = a.metadata.reportingEntity.toLowerCase();
      const nameB = b.metadata.reportingEntity.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'legalAsc') {
    tmpResults.sort((a, b) => {
      const nameA = a.metadata.reportingEntity.toLowerCase();
      const nameB = b.metadata.reportingEntity.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  // if (sortValue === 'legalDesc') {
  //   tmpResults.sort((a, b) => {
  //     const nameA = a.submittedDate;
  //     const nameB = b.submittedDate;
  //     if (nameA < nameB) {
  //       return -1;
  //     }
  //     if (nameA > nameB) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // }
  // if (sortValue === 'legalAsc') {
  //   tmpResults.sort((a, b) => {
  //     const nameA = a.submittedDate;
  //     const nameB = b.submittedDate;
  //     if (nameA > nameB) {
  //       return -1;
  //     }
  //     if (nameA < nameB) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // }
  if (sortValue === 'docDesc') {
    tmpResults.sort((a, b) => {
      const nameA = a.category.toLowerCase();
      const nameB = b.category.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'docAsc') {
    tmpResults.sort((a, b) => {
      const nameA = a.category.toLowerCase();
      const nameB = b.category.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  return tmpResults;
};

// export const clearFilter = (setDashboardData, setActiveFilters, setSortByValue, setSearchValue, getAPIResponse, assignedUser) => { // reportResultsFull, dashboardData, setDashboardData, setActiveFilters, searchValue, setSortByValue, setReportResults, setTotalResults, setTotalPages, loadFilterS, setSearchValue, getAPIResponse
//   const dashboardDataTemp = {
//     search: '',
//     reportingEntityValue: '',
//     parentCompanyValue: '',
//     dealNameValue: '',
//     requirementNameValue: '',
//     syndicationfundValue: '',
//     statusValue: ''
//   }
//   const activeFiltersTemp = {
//     requirementName: '',
//     reportingEntity: '',
//     parentCompany: '',
//     dealName: '',
//     status: '',
//     syndicationFundName: ''
//   }
//   if (sessionStorage.getItem('userrole') === 'employee') {
//     dashboardDataTemp.statusValue = 'Submitted'
//     activeFiltersTemp.status = 'Submitted'
//   }
//   if (sessionStorage.getItem('userrole') === 'portaladmin' || sessionStorage.getItem('userrole') === 'customer') {
//     dashboardDataTemp.statusValue = 'Not Submitted'
//     activeFiltersTemp.status = 'Not Submitted'
//   }
//   sessionStorage.setItem('sessionFilters', JSON.stringify(activeFiltersTemp))
//   setDashboardData(dashboardDataTemp)
//   setActiveFilters(activeFiltersTemp)
//   setSortByValue('dateAsc')
//   setSearchValue('')
//   getAPIResponse(assignedUser, '')
// }

export const handleSearchChange = (
  value,
  reportResultsFull,
  searchValue,
  setReportResults,
  setDashboardData,
  dashboardData,
  setTotalPages,
  setTotalResults,
  activeFilters,
  sortByValue
) => {
  const filteredData = reuseFilterFn(reportResultsFull, activeFilters);
  const searchedData = reuseSearchFn(value,searchValue ,filteredData);
  const temp = reuseSortingFn(sortByValue, searchedData);
  setReportResults(temp);
  setTotalResults(temp.length);
  setTotalPages(Math.ceil(temp.length / 10));
  const dashboardDataTemp = dashboardData;
  dashboardDataTemp.search = value;
  setDashboardData(dashboardDataTemp);
  // filterAllClear(setDashboardData)
};

export const sortFilteredData = (
  e,
  reportResults,
  setReportResults,
  setSortByValue,
  setTotalPages,
  setTotalResults
) => {
  const reportResultsTemp = reuseSortingFn(e.target.value, reportResults);
  setReportResults(reportResultsTemp);
  setTotalPages(Math.ceil(reportResultsTemp.length / 10));
  setTotalResults(reportResultsTemp.length);
  setSortByValue(e.target.value);
};
/*
export const filterEachFn = (activeFilters, reportResultsFull, setReportResults, setDashboardData, dashboardData, sortByValue, setTotalPages, setTotalResults, searchValue, loadFilterS) => { // setStatusValue
  const results = reuseFilterFn(reportResultsFull, activeFilters)
  const initialData = reuseSearchFn(dashboardData.search, searchValue, results)
  const initialDataTemp = reuseSortingFn(sortByValue, initialData)
  if(activeFilters.status.toLowerCase() === 'completed') {
    loadFilterS(reportResultsFull, results, true, false)
  } else {
    loadFilterS(reportResultsFull, results, false, false)
  }
  setReportResults(initialDataTemp)
  setTotalPages(Math.ceil(initialDataTemp.length / 10))
  setTotalResults(initialData.length)
  setDashboardData({
    search: dashboardData.search,
    dealNameValue: activeFilters.dealName,
    reportingEntityValue: activeFilters.reportingEntity,
    parentCompanyValue: activeFilters.parentCompany,
    requirementNameValue: activeFilters.requirementName,
    statusValue: activeFilters.status,
    syndicationfundValue: activeFilters.syndicationFundName,
  })
}
*/

/**
 * A callback function that handles the changes from a USBSelect component.
 *
 * @param {React.ChangeEvent<HTMLSelectElement>} changeEvent The change event from the USBSelect component.
 * @param {string} column The name of the column being filtered.
 * @param {import('../context/DashboardContext').ReportingFilters} activeFilters
 * @param {*} setActiveFilters A dispatch action
 * @param {[import('../context/DashboardContext').ReportingData]} reportResultsFull
 * @param {*} setReportResults A dispatch action
 * @param {*} setDashboardData A dispatch action
 * @param {*} dashboardData The dashboard data.
 * @param {string} sortByValue The column name and direction to sort the data.
 * @param {*} setTotalPages A dispatch action
 * @param {*} setTotalResults A dispatch action
 * @param {string} searchValue The current search value.
 * @param {*} loadFilterS The loadFilterS function in the dashboard context.
 * @param {*} emptyFilters The emptyFilters function in the dashboard context.
 * @param {*} getAPIResponse The getAPIResponse function in the dashboard context.
 */
export const filterData = (
  changeEvent,
  column,
  setStatusData,
  activeFilters,
  setActiveFilters,
  reportResultsFull,
  setReportResults,
  setDashboardData,
  dashboardData,
  sortByValue,
  setTotalPages,
  setTotalResults,
  searchValue,
  loadFilterS,
  emptyFilters,
  getAPIResponse,
  setRefreshUI,
  refreshChildren=true
) => {
  const activeFiltersTemp = activeFilters;
  const selectedValue = changeEvent?.inputValue
    ? changeEvent?.inputValue
    : changeEvent?.target?.value;

  if (activeFiltersTemp[column] === selectedValue) {
    return;
  }

  if (column === 'requirementName') {
    activeFiltersTemp.requirementName = selectedValue;
  }
  if (column === 'reportingEntity') {
    activeFiltersTemp.reportingEntity = changeEvent;
    activeFiltersTemp.dealName = '';
    activeFiltersTemp.syndicationFundName = '';
  }
  if (column === 'parentCompany') {
    activeFiltersTemp.parentCompany = changeEvent;
    if(refreshChildren){
    activeFiltersTemp.reportingEntity = '';
    activeFiltersTemp.dealName = '';
    activeFiltersTemp.syndicationFundName = '';
    }
  }
  if (column === 'asOfDate' && (selectedValue?.length == 10 || selectedValue == undefined)) {
    activeFiltersTemp.asOfDate = selectedValue||"";
  }
  if (column === 'cbDate' && (selectedValue?.length == 10 || selectedValue == undefined)) {
    activeFiltersTemp.cbDate = selectedValue||"";
  }
  if (column === 'submittedDate' && (selectedValue?.length == 10 || selectedValue == undefined)) {
    activeFiltersTemp.submittedDate = selectedValue||"";;
  }
  if (column === 'dealName') {
    activeFiltersTemp.dealName = changeEvent;
  }
  if (column === 'status') {
    activeFiltersTemp.status = selectedValue;
    setStatusData(selectedValue);
  }
  if (column === 'followCheck') {
    activeFiltersTemp.following = selectedValue;
  }
  if (column === 'syndicationFundName') {
    activeFiltersTemp.syndicationFundName = changeEvent;
  }

  setActiveFilters(activeFiltersTemp);
  sessionStorage.setItem('sessionFilters', JSON.stringify(activeFiltersTemp));
  setDashboardData({
    search: dashboardData.search,
    dealNameValue: activeFiltersTemp.dealName,
    reportingEntityValue: activeFiltersTemp.reportingEntity,
    parentCompanyValue: activeFiltersTemp.parentCompany,
    asOfDateValue: activeFiltersTemp.asOfDate,
    cbDateValue: activeFilters.cbDate,
    submittedDateValue: activeFiltersTemp.submittedDate,
    requirementNameValue: activeFiltersTemp.requirementName,
    statusValue: activeFiltersTemp.status,
    syndicationfundValue: activeFiltersTemp.syndicationFundName,
    followValue: activeFilters.following,
  });
  const remote_customer = JSON.parse(sessionStorage.getItem('remote_user'))
    ? JSON.parse(sessionStorage.getItem('remote_user')).userID
    : null;
  const view_as = sessionStorage.getItem('view_as')
    ? `${JSON.parse(sessionStorage.getItem('view_as')).bankerId}@cdcemp`
    : null;
  // const initialFiltered = initialFilter(reportResultsFull)
  if (column === 'status') {
    if (remote_customer) {
      getAPIResponse(remote_customer, activeFiltersTemp.status, true);
    } else if (view_as) {
      getAPIResponse(view_as, activeFiltersTemp.status);
    } else {
      getAPIResponse(
        sessionStorage.getItem('assignedUser'),
        activeFiltersTemp.status
      );
    }
  } else {
    const filteredResults = reuseFilterFn(reportResultsFull, activeFiltersTemp);
    const searchedData = reuseSearchFn(
      dashboardData.search,
      searchValue,
      filteredResults
    );
    const sortedData = reuseSortingFn(sortByValue, searchedData);
    setReportResults(sortedData);
    setTotalPages(Math.ceil(sortedData.length / 10));
    setTotalResults(sortedData.length);
    // if (sortedData.length > 0) {
      // loadFilterS(sortedData, column);
      setRefreshUI(false)
    // } else {
    //   emptyFilters(activeFiltersTemp);
    //   setRefreshUI(false)
    // }
  }

  // let completedStatus
  // if (column === 'status' && e.value.toLowerCase() === 'completed') {
  //   completedStatus = true
  // } else {
  //   completedStatus = false
  // }
  // initialApi( sessionStorage.getItem('assignedUser'), completedStatus )
  //   .then(resultResponse => {
  //     const initialFiltered = initialFilter(resultResponse, false)
  //     const filteredResults = reuseFilterFn(initialFiltered, activeFiltersTemp)
  //     const searchedData = reuseSearchFn(dashboardData.search, searchValue, filteredResults)
  //     const soretedData = reuseSortingFn(sortByValue, searchedData)
  //     setReportResults(soretedData)
  //     setTotalPages(Math.ceil(soretedData.length / 10))
  //     setTotalResults(soretedData.length)
  //     loadFilterS(resultResponse.data, soretedData, completedStatus, false)
  //   })
  /*
  if (column === 'status' && e.value.toLowerCase() === 'completed') {
    loadData(sessionStorage.getItem('assignedUser'), true, false)
  } else {
    loadData(sessionStorage.getItem('assignedUser'), false, false)
  }

  if (column !== 'status') {
    filterEachFn(activeFiltersTemp, reportResultsFull, setReportResults, setDashboardData, dashboardData, sortByValue, setTotalPages, setTotalResults, searchValue, loadFilterS) // setStatusValue
  } else {
    setDashboardData({
      search: dashboardData.search,
      dealNameValue: activeFiltersTemp.dealName,
      reportingEntityValue: activeFiltersTemp.reportingEntity,
      parentCompanyValue: activeFiltersTemp.parentCompany,
      requirementNameValue: activeFiltersTemp.requirementName,
      statusValue: activeFiltersTemp.status,
      syndicationfundValue: activeFiltersTemp.syndicationFundName,
    })
    if (e.target.value.toLowerCase() === 'completed') {
      loadData(sessionStorage.getItem('assignedUser'), true, false)
    } else {
      loadData(sessionStorage.getItem('assignedUser'), false, false)
    }
  }
  */
};

export const tblContentTemplate = (item, filterStatus, setActionsDrop,tempUserRole,setLoading,setCurrentPage,currentPage,includesCBDocs,statusData) => {
  const docDate = item.dueDate;
  const docdueDate = moment(item.dueDate, 'YYYYMMDD').format('YYYYMMDD');
  let dateHtml;
  let dueHtml = '';
 
  const disabledID = sessionStorage.getItem('userrole') === 'employee' && !sessionStorage.getItem('remote_user') && sessionStorage.getItem('userData') && JSON.parse(sessionStorage.getItem('userData')).emailId ? false : true; 
 
  const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
  if (docDate != null) {
    const days = moment(docdueDate, 'YYYYMMDD').diff(
      moment(today, 'YYYYMMDD'),
      'days'
    );
    if (days === 0 && item.status === 'Not Submitted') {
      dueHtml = <div className="date-details">Due today</div>;
    } else if (days < 0 && item.status === 'Not Submitted') {
      dueHtml = (
        <div className="font-red date-details">
          <span className="date-alert">
            <img src={dateAlertIcon} />
          </span>
          Past due
        </div>
      );
    } else if (days > 0 && item.status !== 'Completed') {
      dueHtml = (
        <div className="date-details">
          Due in&nbsp;
          {days}
          &nbsp;
          {days > 1 ? 'days' : 'day'}
        </div>
      );
    }
    dateHtml = (
      <div>
        {moment(docdueDate).format('MM/DD/YYYY')}
        <div>{dueHtml}</div>
      </div>
    );
  } else dateHtml = '';

  let dealNametext = '';
  let dealNumberText;
  if (
    (item.metadata.syndicationFundName === '' ||
      item.metadata.syndicationFundName === null) &&
    (item.metadata.dealName === '' || item.metadata.dealName === null)
  ) {
    dealNametext = 'Not applicable';
    dealNumberText = '';
  } else if (
    item.metadata.syndicationFundName === '' ||
    item.metadata.syndicationFundName === null
  ) {
    dealNametext = item.metadata.dealName;
    dealNumberText = item.metadata.dealId;
  } else if (
    (item.metadata.dealName === '' || item.metadata.dealName === null) &&
    item.metadata.showSyndicationFundInd === true
  ) {
    dealNametext = item.metadata.syndicationFundName;
    dealNumberText = item.metadata.syndicationFund;
  } else if (
    (item.metadata.dealName === '' || item.metadata.dealName === null) &&
    item.metadata.showSyndicationFundInd === false
  ) {
    dealNametext = 'Not applicable';
    dealNumberText = '';
  } else if (
    sessionStorage.getItem('userrole') !== 'employee' &&
    item.metadata.showSyndicationFundInd === false
  ) {
    dealNametext = item.metadata.dealName;
    dealNumberText = item.metadata.dealId;
  } else {
    dealNametext = `${item.metadata.dealName} | ${item.metadata.syndicationFundName}`;
    dealNumberText = item.metadata.dealId;
  }

  const legalEntityHtml = item.metadata.reportingEntity;
  const parentCompanyHtml = item.metadata.parentCompanyName;

  let docCategory = '';
  if (item.category === null) {
    docCategory = <div className="empty-div" />;
  } else docCategory = item.category;
  const docRequirement = item.requirementName;

  const asofDate = item.asOfDate ? item.asOfDate : "-";
  const cbDate =  includesCBDocs && item.category=="Closing Binder" ? item.closingBinderDate ? moment.utc(item.closingBinderDate).format('MM/DD/YYYY') : "N/A" : "N/A"
  
  let asofdateHtml;
  if(includesCBDocs){
    asofdateHtml = <div>
      <div>{asofDate}<hr/>{cbDate}</div>
    </div>
  } else {
    asofdateHtml = <div>{asofDate}</div>;
  }

  const receivedDate = item.submittedDate;
  let receiveddateHtml;
  if (receivedDate != null)
  receiveddateHtml = (
      <div>{moment(item.submittedDate).format('MM/DD/YYYY HH:mm:ss')}</div>
    );
  else receiveddateHtml = <div>-</div>;

  const statusType = item.status;
  let statusHtml = '';
  if (statusType.toLowerCase() === 'needs action')
    statusHtml = (
      <div className="font-red to-uppercase">
        {statusType}
        <span className="notification">#</span>
      </div>
    );
  else if (statusType.toLowerCase() === 'submitted')
    statusHtml = (
      <div className="data-table-cell to-uppercase">
        <div>{statusType}</div>
        {item.submittedDate &&
        item.submittedDate !== '' &&
        item.submittedDate !== null ? (
          <div>{moment(item.submittedDate).format('MM/DD/YYYY')}</div>
        ) : (
          ''
        )}
      </div>
    );
  else
    statusHtml = (
      <div className="data-table-cell to-uppercase">{statusType}</div>
    );
  const follow = (follow, item, setActionsDrop,setLoading,setCurrentPage,currentPage) => {
   
    // console.log(currentPage);
    const data = {
      userId: sessionStorage.getItem('loggedUser'),
      subscribed: follow,
      documentId: item.id,
      emailId: JSON.parse(sessionStorage.getItem('userData')).emailId,
      name:
        JSON.parse(sessionStorage.getItem('userData')).firstName +
        ' ' +
        JSON.parse(sessionStorage.getItem('userData')).lastName,
    };
    if (item.id) {
      setCurrentPage(currentPage);
      const listUrl = `${endpoints.apigee}/document-management/cdc/requirements/v1/requirements-subscribe`;
      callApi
        .postApiLoggedUser(listUrl, data)
        .then((response) => {
          setActionsDrop(item.id);
          setTimeout(() => {
            setLoading(false);
          }, 500);
          
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const followUp = (id, following,setActionsDrop,setLoading,setCurrentPage,currentPage) => {
    const currentPageTemp=currentPage;
    setLoading(true);

    const maxCount = sessionStorage.getItem('maxCount')
      ? JSON.parse(sessionStorage.getItem('maxCount'))
      : 100;
    const followingCount = sessionStorage.getItem('followingCount')
      ? JSON.parse(sessionStorage.getItem('followingCount'))
      : 0;
    if (followingCount < maxCount) {
      if (following === false && item.id === id) {
        follow(true, item, setActionsDrop,setLoading,setCurrentPage,currentPageTemp);
        following=true;
      } else if (following === true && item.id === id) {
        follow(false, item, setActionsDrop,setLoading,setCurrentPage,currentPageTemp);
        following=false;
      }
    } else if (
      following === false &&
      confirm(
        `You are already following ${maxCount} documents. Are you sure you want to follow?`
      )
    ) {
      if (following === false && item.id === id) {
        follow(true, item, setActionsDrop,setLoading,setCurrentPage,currentPageTemp);
      }
    } else {
      if (following === true && item.id === id) {
        follow(false, item, setActionsDrop,setLoading,setCurrentPage,currentPageTemp);
      }
    }
  
    setActionsDrop(item.id);
    setTimeout(() => {
      setLoading(false);
    }, 100);

  };
  const docId = item.id;
  let tableDataObject = {
    first: dateHtml,
    second: (
      <div>
        <div className="span-bold">{dealNametext}</div>
        <div className="data-table-subcell">{dealNumberText}</div>
      </div>
    ),
    third: (
      <div>
        <div className="span-bold">{parentCompanyHtml}</div>
        <div className="data-table-subcell">{legalEntityHtml}</div>
      </div>
    ),
    fourth: (
      <div className="data-table-cell">
        <div className="span-bold">{docCategory}</div>
        <div className="data-table-subcell">{docRequirement}</div>
        {item.portalSubmitted ? (
          <div className="expand-column-portal">
            <div className="portal-indicator">
              <img src={portalIndicator} />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    ),
    fifth: asofdateHtml, }
  if (statusType != 'Not Submitted' || filterStatus == 'Status' || statusData == 'Status') {
    if(!sessionStorage.getItem('remote_user') && tempUserRole === 'employee'){
      return { ...tableDataObject,
        sixth: receiveddateHtml,
        seventh: statusHtml,
        eighth: (
          <div>
            <div className="expand-column" data-testid="chck-box">
             <Checkbox
                onChange={() => {
                  followUp(item.id, item.following,setActionsDrop,setLoading,setCurrentPage,currentPage);
                  item.following=!item.following;
                }
                }
                checked={item.following}
                disabled={disabledID}
                ariaLabelledBy="mark-final"
                tooltip={
                  disabledID
                    ? 'User must have assigned portfolio to follow'
                    : 'Click here to Follow'
                }
              />
            </div>
          </div>
        ),
        ninth: (
          <div className="expand-column">
            <a href={ docCategory=="Closing Binder" ? `/ClosingBinders/Portfolio/Requirements?id=${docId}` : `/RequirementDetails?id=${docId}`} title="View details page">
              <div className="expand-column-off">
                <img src={actionlogo}></img>
              </div>
            </a>
          </div>
        ),
      };
    }
    return {
      ...tableDataObject,
      sixth: receiveddateHtml,
        seventh: statusHtml,
        eighth : (
          <div className="expand-column">
            <a href={ docCategory=="Closing Binder" ? `/ClosingBinders/Portfolio/Requirements?id=${docId}` : `/RequirementDetails?id=${docId}`} title="View details page">
              <div className="expand-column-off">
                <img src={actionlogo}></img>
              </div>
            </a>
          </div>
        ),
    }
  }
  if(!sessionStorage.getItem('remote_user') && tempUserRole === 'employee'){
    return {
      ...tableDataObject,
      sixth: statusHtml,
      seventh: (
        <div>
          <div className="expand-column" data-testid="chck-box">
            <Checkbox
              onChange={() =>{
                followUp(item.id, item.following,setActionsDrop,setLoading,setCurrentPage,currentPage);
                  item.following=!item.following;
              }}
              checked={item.following}
              disabled={disabledID}
              ariaLabelledBy="mark-final"
              tooltip={
                disabledID
                  ? 'User must have assigned portfolio to follow'
                  : 'Click here to Follow'
              }
            />
          </div>
        </div>
      ),
      eighth: (
        <div className="expand-column">
          <a href={ docCategory=="Closing Binder" ? `/ClosingBinders/Portfolio/Requirements?id=${docId}` : `/RequirementDetails?id=${docId}`} title="View details page">
              <div className="expand-column-off">
                <img src={actionlogo}></img>
              </div>
            </a>
        </div>
      ),
    };
  }
  return {
    ...tableDataObject,
    sixth: statusHtml,
    seventh : (
      <div className="expand-column">
        <a href={ docCategory=="Closing Binder" ? `/ClosingBinders/Portfolio/Requirements?id=${docId}` : `/RequirementDetails?id=${docId}`} title="View details page">
          <div className="expand-column-off">
            <img src={actionlogo}></img>
          </div>
        </a>
      </div>
    ),
  }
};

// return (
//   <tr className="separaterow">
//     <td>{dateHtml}</td>
//     <td width="24%">
//       <div className="data-table-cell">
//         <div className="span-bold">{dealNametext}</div>
//         <div className="data-table-subcell">{dealNumberText}</div>
//       </div>
//     </td>
//     <td width="25%">
//       <div className="data-table-cell">
//         <div className="span-bold">{parentCompanyHtml}</div>
//         <div className="data-table-subcell">{legalEntityHtml}</div>
//       </div>
//     </td>
//     <td width="20%">
//       <div className="data-table-cell">
//         <div className="span-bold">{docCategory}</div>
//         <div className="data-table-subcell">{docRequirement}</div>
//         {item.portalSubmitted ? (
//         <div className="expand-column-portal">
//           <div className="portal-indicator"></div>
//         </div>):('') }
//       </div>
//     </td>
//     <td width="40px">{asofdateHtml}</td>
//     {statusType.toLowerCase() != 'not submitted' ? (
//       <td width="10%">{receiveddateHtml}</td>
//     ) : null}

//     <td width="10%">{statusHtml}</td>
//     <td width="5%">
//       <div className="expand-column">
//         <a href={`/RequirementDetails?id=${docId}`} title="View details page">
//           <div className="expand-column-off" />
//         </a>
//       </div>
//     </td>
//   </tr>
// )

export const searchDrop = (e, 
  setSearchValue,
  reportResultsFull,
  searchValue,
  setReportResults,
  setDashboardData,
  dashboardData,
  setTotalPages,
  setTotalResults,
  activeFilters,
  sortByValue) => {
  setDashboardData({
    ...dashboardData,
    search : ''
  })
  handleSearchChange(
    '',
    reportResultsFull,
    searchValue,
    setReportResults,
    setDashboardData,
    dashboardData,
    setTotalPages,
    setTotalResults,
    activeFilters,
    sortByValue
  )
  setSearchValue(e.value);
};

export const downloadDataCheck = (reportResultsFull) => {
  let count = 0;
  reportResultsFull.forEach((item) => {
    const dueDate = item.dueDate.split('T');
    const docdueDate = moment(dueDate, 'YYYYMMDD').format('YYYYMMDD');
    const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
    if (docdueDate != null) {
      const days = moment(docdueDate, 'YYYYMMDD').diff(
        moment(today, 'YYYYMMDD'),
        'days'
      );
      if (days <= -30 && item.status === 'Not Submitted') {
        count += 1;
      }
    }
  });
  if (count > 0) {
    return true;
  }
  return false;
};

export const ReportingModal = (
  whichReport,
  selectedReportDataSet,
  reportDataSet,
  reportingModal,
  setReportingModal,
  hideReportingModal,
  fileType,
  setFileType,
  hideExcel,
  setHideExcel
) => {
  const [excelData, setExcelData] = useState([]);

  const fileTypes = [{ label: 'Microsoft Excel (xlsx)', value: 'xlsx' }];

  let title = '';
  let filename = '';
  if (whichReport === 'pastdue') {
    title = 'Download all past due reporting (30 days or more) report';
    filename = 'Past_Due_Report';
  } else if (whichReport === 'portfolio') {
    title = 'My portfolio report.';
    filename = 'My_Portfolio_Report';
  }
  const addAriaLabel = () => {
    const searchElement = document.getElementsByClassName('p-dropdown-trigger');
    for (let i = 0; i < searchElement.length; i += 1) {
      searchElement[i].setAttribute('aria-label', 'Click to show more');
    }
  };

  const setDownloadFile = (e) => {
    let tempdata = [];
    const now = moment();
    if (whichReport === 'pastdue') {
      tempdata = [];
      reportDataSet.forEach((item) => {
        const dueDate = item.dueDate.split('T');
        const docdueDate = moment(dueDate, 'YYYYMMDD').format('YYYYMMDD');
        const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
        if (docdueDate != null) {
          const days = moment(docdueDate, 'YYYYMMDD').diff(
            moment(today, 'YYYYMMDD'),
            'days'
          );
          if (days <= -30 && item.status === 'Not Submitted') {
            tempdata.push({
              REPORT_DATE_OF_CREATION: now.format('MMM DD, YYYY'),
              DUE_DATE: moment(dueDate[0]).format('MMM DD, YYYY'),
              AS_OF_DATE: moment(item.asOfDate).format('MMM DD, YYYY'),
              DEAL_NAME: item.metadata.dealName,
              DEAL_NUMBER: item.metadata.dealId,
              PARENT_COMPANY: item.metadata.parentCompanyName,
              SUBMITTED_DATE: item.submittedDate,
              LEGAL_ENTITY: item.metadata.reportingEntity,
              DOCUMENT_TYPE: item.category,
              'DOCUMENT_SUBTYPE(CATEGORY)': item.requirementName,
              STATUS: item.status,
              ...(sessionStorage.getItem('userrole') === 'employee'
                ? { FUND_NAME: item.metadata.syndicationFundName }
                : ''),
            });
          }
        }
      });
    } else if (whichReport === 'portfolio') {
      tempdata = [];
      selectedReportDataSet.forEach((item) => {
        tempdata.push({
          REPORT_DATE_OF_CREATION: now.format('MMM DD, YYYY'),
          DUE_DATE: moment(item.dueDate).format('MMM DD, YYYY'),
          AS_OF_DATE: moment(item.asOfDate).format('MMM DD, YYYY'),
          DEAL_NAME: item.metadata.dealName,
          DEAL_NUMBER: item.metadata.dealId,
          PARENT_COMPANY: item.metadata.parentCompanyName,
          SUBMITTED_DATE: item.submittedDate,
          LEGAL_ENTITY: item.metadata.reportingEntity,
          DOCUMENT_TYPE: item.category,
          'DOCUMENT_SUBTYPE(CATEGORY)': item.requirementName,
          STATUS: item.status,
          ...(sessionStorage.getItem('userrole') === 'employee'
            ? { FUND_NAME: item.metadata.syndicationFundName }
            : ''),
        });
      });
    }
    setExcelData(tempdata);
    setFileType(e.target.value);
    if (e.target.value === 'pdf') {
      setHideExcel(false);
    } else {
      setHideExcel(true);
    }
  };

  useEffect(() => {
    addAriaLabel();
  });

  return (
    <USBModal className="download-modal" isOpen={reportingModal}>
      <USBModalCloseIcon
        handleModalClose={() => hideReportingModal(setReportingModal)}
        className="errormodalclosebtn"
      />
      <USBModalHeader modalTitle={title} />
      <USBModalBody>
        <div className="modal-description">
          To download the report, first choose your preferred format from
          the&nbsp;
          <strong>File type</strong>
          &nbsp;menu
        </div>
        <div>
          <div className="download-file-type-dropdown">
            <Dropdown
              value={fileType}
              options={fileTypes}
              onChange={(e) => setDownloadFile(e)}
              placeholder="File Type"
              className="file-type"
              ariaLabel="file type"
            />
          </div>
        </div>
      </USBModalBody>
      <USBModalFooter>
        <div className="reportDownloadModal">
          <span className="report-cancel-btn">
            <USBButton addClasses={'button-hover-white'}
              handleClick={() => hideReportingModal(setReportingModal)}
            >
              Cancel
            </USBButton>
          </span>
          {hideExcel && (
            <USBButton
              variant="primary"
              handleClick={() =>
                ExportToExcel(
                  Object.values(excelData),
                  filename,
                  setReportingModal,
                  setHideExcel
                )
              }
            >
              Download
            </USBButton>
          )}
        </div>
      </USBModalFooter>
    </USBModal>
  );
};

const ReportingDashboard = () => {
      
  const {permissionDetails}=useContext(PermissionRoleContext);

  const [employeeFlag, setEmployeeFlag] = useState(false);
  // const [portalAdmin, setPortalAdmin] = useState(false)
  const [tempSessions, setTempSessions] = useState(false);
  const [reportingModal, setReportingModal] = useState(false);
  const [hideExcel, setHideExcel] = useState(false);
  const [fileType, setFileType] = useState('');
  const [whichReport, setWhichReport] = useState('');
  const [rangeArray, setRangeArray] = useState(['25', '50', '75', '100']);
  const [checked, setChecked] = useState(false);
  const [refreshUI,setRefreshUI] = useState(false)
  const history = useHistory();
  const {
    reportResults,
    setReportResults,
    reportResultsFull,
    reportingEntity,
    dealName,
    requirementName,
    statusDrop,
    parentCompany,
    submittedDate,
    asOfDate,
    loading,
    setLoading,
    dashboardData,
    setDashboardData, // statusValue, setStatusValue,
    syndicationFund,
    sortBy,
    searchData,
    totalPages,
    setTotalPages,
    totalResults,
    setTotalResults,
    activeFilters,
    setActiveFilters,
    loadFilterS,
    getAPIResponse,
    emptyFilters,
    sortByValue,
    setSortByValue,
    searchValue,
    setSearchValue,
    clearFilter,
    clearFilterForReports,
    showSyndication,
    setReportingEntity,
    followCheck,
    parentCompanyCpy,
    reportingEntityCpy,
    dealNameCpy,
    syndicationFundCpy,
  } = useContext(DashboardContext);
  const [actionsDrop, setActionsDrop] = useState();
  const { assignedUserName, servicingUserRole, setCurrentMenu } =
    useContext(DocContext);
  const [filterReportingEntity, setFilterEntity] = useState([]);
  const [filterDealName, setfilterDealName] = useState([]);
  const [filterParentCompany, setfilterParentCompany] = useState([]);
  const [filterFundName, setfilterFundName] = useState([]);
  const [contentOpen, setContentOpen] = useState(false);
  const [contentOpenDeal, setcontentOpenDeal] = useState(false);
  const [contentOpenParent, setcontentOpenParent] = useState(false);
  const [contentOpenFund, setcontentOpenFund] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTemp, setCurrentPageTemp] = useState(1);
  const [statusData, setStatusData] = useState('');

  const tempUserRole = useUserRole();
  const pccpy = JSON.stringify(parentCompanyCpy);
  // const forwardClickHandler = () => {
  //   setCurrentPage(currentPage + 1);
  //   alert(currentPage);
  // };
  // const backwardClickHandler = () => {
  //   setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
  //   alert(currentPage);
  // };
  // const controlOpts = {
  //   controlled: true,
  //   handleForwardClick: forwardClickHandler,
  //   handleBackwardClick: backwardClickHandler,
  // };

  useEffect(() => {
    document.addEventListener('click', handleClickDropdown, true);
  }, []);

  useEffect(() => {
    if (getQS('a') === 'c') {
      setTempSessions(true);
    }
    if (!sessionStorage.getItem('tok')) {
      history.push('/');
    } else {
      setCurrentMenu('portfolio');
      setUserRole();
      if (JSON.parse(sessionStorage.getItem('remote_user'))) {
        getAPIResponse(
          JSON.parse(sessionStorage.getItem('remote_user')).userID,
          'Status',
          true
        );
      } else if (sessionStorage.getItem('view_as')) {
        getAPIResponse(
          `${JSON.parse(sessionStorage.getItem('view_as')).bankerId}@cdcemp`,
          'Status',
          true
        );
      } else {
        getAPIResponse(sessionStorage.getItem('assignedUser'), ''); // , false, true
      }

      if (
        sessionStorage.getItem('userrole') === 'employee' ||
        servicingUserRole === 'employee'
      ) {
        setEmployeeFlag(true);
      }
    }
  }, []);

  const includesCBDocs = reportResultsFull.filter((item)=>item.category=="Closing Binder").length > 0 ? true : false; 

  // CHANGED - TO CONVERT TO INTERNAL USER
  // Temp method to set session values in local dev

  const items = dashboardData;
  const showReportingModal = (setUploadedDocModalOpen, reportName) => {
    setWhichReport(reportName);
    setFileType('');
    setUploadedDocModalOpen(true);
  };
  const hideReportingModal = (setUploadedDocModalOpen, reportName) => {
    setWhichReport(reportName);
    setFileType('');
    setUploadedDocModalOpen(false);
    setHideExcel(false);
  };
  const paginationtableData = (reportResults, filterStatus,tempUserRole,setLoading,setActionsDrop,setCurrentPage,currentPage) => {
    let paginationData = [];

    if (reportResults.length != 0) {
      paginationData = [];
      reportResults.map((item) => {
        paginationData.push(
          tblContentTemplate(item, filterStatus, setActionsDrop,tempUserRole,setLoading,setCurrentPage,currentPage,includesCBDocs,statusData)
        );
      });
    }

    return paginationData;
  };

  const defaultContent = {
    dateInput: {
      calendarButtonAriaLabel: 'Choose date',
      helperText: 'mm/dd/yyyy',
      helperTextOptional: 'Optional',
      labelText: 'Received date',
      labelText2: 'As of Date',
      errorMessages: {
        default: 'Please enter a valid date.',
        202: 'Date should be in the past.',
        203: 'Date should be in the future.',
        221: 'Enter no more than 10 characters.',
        213: 'Enter numbers only.',
        224: '(Optional field)',
        228: "That date isn't available. Please try again.",
      },
    },
  };

  const defaultSettingsDateInput = {
    initValue: activeFilters?.submittedDate?.length==10 ? activeFilters?.submittedDate : undefined,
    isDateOfBirth: true,
    minimumAge: 0,
    maximumAge: 10,
    maxlength: 10,
    showRenderCount: false,
    inputName: 'date-picker_single-date--default',
    isDisabled: false,
    isOptional: false,
    isReadOnly: false,
    dataTestId: null,
  };

  const defaultSettingsCalendar = {
    areBankHolidaysDisabled: defaultSettingsDateInput.isDateOfBirth
      ? false
      : true,
    areWeekendsDisabled: defaultSettingsDateInput.isDateOfBirth ? false : true,
    occurrence: defaultSettingsDateInput.isDateOfBirth ? 'past' : 'future',
    numberOfYearsToShow: '10',
  };

  const defaultContent1 = {
    dateInput: {
      calendarButtonAriaLabel: 'Choose date',
      helperText: 'mm/dd/yyyy',
      helperTextOptional: 'Optional',
      labelText: 'As of Date',
      errorMessages: {
        default: 'Please enter a valid date.',
        202: 'Date should be in the past.',
        203: 'Date should be in the future.',
        221: 'Enter no more than 10 characters.',
        213: 'Enter numbers only.',
        224: '(Optional field)',
        228: "That date isn't available. Please try again.",
      },
    },
  };

  const defaultSettingsDateInput1 = {
    initValue:  activeFilters?.asOfDate || undefined,
    isDateOfBirth: true,
    minimumAge: 0,
    maximumAge: 10,
    maxlength: 10,
    showRenderCount: false,
    inputName: 'date-picker_single-date--default1',
    isDisabled: false,
    isOptional: false,
    isReadOnly: false,
    dataTestId: null,
  };

  const defaultSettingsCalendar1 = {
    areBankHolidaysDisabled: defaultSettingsDateInput1.isDateOfBirth
      ? false
      : true,
    areWeekendsDisabled: defaultSettingsDateInput1.isDateOfBirth ? false : true,
    occurrence: defaultSettingsDateInput1.isDateOfBirth ? 'past' : 'future',
    numberOfYearsToShow: '10',
  };

  const updateRangeArray = (pageCount) => {
    let dropdownSpans = document.getElementsByClassName('dropdown__btn-selection selected')
    if(dropdownSpans && reportResults && reportResults.length>24){
      dropdownSpans[0].childNodes[0].data = dropdownSpans[1].childNodes[0].data = pageCount
    }
  };

  const loadByEntityName = (
    item,
    columnName,
    activeFilters,
    setActiveFilters,
    reportResultsFull,
    setReportResults,
    setDashboardData,
    dashboardData,
    sortByValue,
    setTotalPages,
    setTotalResults,
    searchValue,
    loadFilterS,
    emptyFilters,
    getAPIResponse,
    refreshChildren=true
  ) => {
    
    if(refreshChildren){
    setRefreshUI(true)}
    filterData(
      item,
      columnName,
      setStatusData ,
      activeFilters,
      setActiveFilters,
      reportResultsFull,
      setReportResults,
      setDashboardData,
      dashboardData,
      sortByValue,
      setTotalPages,
      setTotalResults,
      searchValue,
      loadFilterS,
      emptyFilters,
      getAPIResponse,
      setRefreshUI,
      refreshChildren
    );
    setContentOpen(false);
    setcontentOpenDeal(false);
    setcontentOpenParent(false);
    setcontentOpenFund(false);
    // setEmployeeName(employeeNameFull);
    // if (currentMenu === 'notifications') {
    //   listNotifications(item.userId);
    // }
  };

  const compNameKepress = (event, item) => {
    if (event.keyCode === 27) {
      setContentOpen(false);
      setcontentOpenParent(false);
      setcontentOpenFund(false);
      setcontentOpenDeal(false);
    }
  };
  const searchDropFilter = (value) => {
    setcontentOpenParent(false);
    setcontentOpenDeal(false);
    setcontentOpenFund(false);
    let temp = [];
    setFilterEntity([]);
    setActiveFilters((prev)=>{
      return {...prev,
        reportingEntity : value
      }
    })
    if (value !== '' || value.length !== 0) {
      reportingEntity.forEach((item) => {
        if (
          (item.content !== null && item.content !== '') ||
          (item.value !== null && item.value !== '')
        ) {
          const itemValue = '' + item.value;
          const itemContent = '' + item.content;
          const inputValue = '' + value;
          if (
            itemValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            itemContent.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ) {
            temp.push(item);
            setContentOpen(true);
          }
        } else {
          setContentOpen(false);
        }
      });
      setFilterEntity(temp);
    } else {
      temp = reportingEntity;
      setFilterEntity(temp);
      loadByEntityName(
        '',
        'reportingEntity',
        activeFilters,
        setActiveFilters,
        reportResultsFull,
        setReportResults,
        setDashboardData,
        dashboardData,
        sortByValue,
        setTotalPages,
        setTotalResults,
        searchValue,
        loadFilterS,
        emptyFilters,
        getAPIResponse
      );
    }
    setContentOpen(true);
  };
  const toggleContent = () => {
    setFilterEntity(reportingEntityCpy);
    if (contentOpen) {
      setContentOpen(false);
    } else {
      setContentOpen(true);
      setcontentOpenDeal(false);
      setcontentOpenFund(false);
      setcontentOpenParent(false);
      // setReportingEntity(reportingEntity);
    }
    // setContentOpen(true);
  };

  const handleClickDropdown = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      if (
      event.srcElement.className !== 'down-image' &&
        event.srcElement.className !== 'dropdown-each'
      ) {
        setContentOpen(false);
        setcontentOpenDeal(false);
        setcontentOpenFund(false);
        setcontentOpenParent(false);
      }
    }
  };

  const toggleContentDealName = () => {
    setfilterDealName(dealNameCpy);
    if (contentOpenDeal) {
      setcontentOpenDeal(false);
    } else {
      setcontentOpenDeal(true);
      setContentOpen(false);
      setcontentOpenFund(false);
      setcontentOpenParent(false);
    }
  };
  const toggleContentFund = () => {
    setfilterFundName(syndicationFundCpy);
    if (contentOpenFund) {
      setcontentOpenFund(false);
    } else {
      setcontentOpenFund(true);
      setcontentOpenDeal(false);
      setContentOpen(false);
      setcontentOpenParent(false);
    }
  };
  const searchDropFilterFund = (value) => {
    setContentOpen(false);
    setcontentOpenDeal(false);
    setcontentOpenParent(false);
    let temp = [];
    setfilterFundName([]);
    setActiveFilters((prev)=>{
      return {...prev,
        syndicationFundName : value
      }
    })
    if (value !== '' || value.length !== 0) {
      syndicationFund.forEach((item) => {
        if (
          (item.content !== null && item.content !== '') ||
          (item.value !== null && item.value !== '')
        ) {
          const itemValue = '' + item.value;
          const itemContent = '' + item.content;
          const inputValue = '' + value;
          if (
            itemValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            itemContent.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ) {
            temp.push(item);
            setcontentOpenFund(true);
          }
        } else {
          setcontentOpenFund(false);
        }
      });
      setfilterFundName(temp);
    } else {
      temp = syndicationFund;
      setfilterFundName(temp);
      loadByEntityName(
        '',
        'syndicationFundName',
        activeFilters,
        setActiveFilters,
        reportResultsFull,
        setReportResults,
        setDashboardData,
        dashboardData,
        sortByValue,
        setTotalPages,
        setTotalResults,
        searchValue,
        loadFilterS,
        emptyFilters,
        getAPIResponse
      );
    }

    setcontentOpenFund(true);
  };

  const searchDropFilterDeal = (value) => {
    setContentOpen(false);
    setcontentOpenParent(false);
    setcontentOpenFund(false);
    let temp = [];
    setfilterDealName([]);
    setActiveFilters((prev)=>{
      return {...prev,
        dealName : value
      }
    })
    if (value !== '' || value.length !== 0) {
      dealName.forEach((item) => {
        if (
          (item.content !== null && item.content !== '') ||
          (item.value !== null && item.value !== '')
        ) {
          const itemValue = '' + item.value;
          const itemContent = '' + item.content;
          const inputValue = '' + value;
          if (
            itemValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            itemContent.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ) {
            temp.push(item);
            setcontentOpenDeal(true);
          }
        } else {
          setcontentOpenDeal(false);
        }
      });
      setfilterDealName(temp);
    } else {
      temp = dealName;
      setfilterDealName(temp);
      loadByEntityName(
        '',
        'dealName',
        activeFilters,
        setActiveFilters,
        reportResultsFull,
        setReportResults,
        setDashboardData,
        dashboardData,
        sortByValue,
        setTotalPages,
        setTotalResults,
        searchValue,
        loadFilterS,
        emptyFilters,
        getAPIResponse
      );
    }

    setcontentOpenDeal(true);
  };
  const toggleContentParentCompany = () => {
    setfilterParentCompany(parentCompanyCpy);
    if (contentOpenParent) {
      setcontentOpenParent(false);
    } else {
      setcontentOpenParent(true);
      setContentOpen(false);
      setcontentOpenDeal(false);
      setcontentOpenFund(false);
    }

    // setContentOpen(true);
  };
  const searchDropFilterParentCompany = (value) => {
    setContentOpen(false);
    setcontentOpenDeal(false);
    setcontentOpenFund(false);
    let temp = [];
    setfilterParentCompany([]);
    setActiveFilters((prev)=>{
      return {...prev,
        parentCompany : value
      }
    })
    if (value !== '' || value.length !== 0) {
      parentCompany.forEach((item) => {
        if (
          (item.content !== null && item.content !== '') ||
          (item.value !== null && item.value !== '')
        ) {
          const itemValue = '' + item.value;
          const itemContent = '' + item.content;
          const inputValue = '' + value;
          if (
            itemValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            itemContent.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ) {
            temp.push(item);
            setcontentOpenParent(true);
          }
        } else {
          setcontentOpenParent(false);
        }
      });
      setfilterParentCompany(temp);
    } else {
      temp = parentCompany;
      setfilterParentCompany(temp);
      loadByEntityName(
        '',
        'parentCompany',
        activeFilters,
        setActiveFilters,
        reportResultsFull,
        setReportResults,
        setDashboardData,
        dashboardData,
        sortByValue,
        setTotalPages,
        setTotalResults,
        searchValue,
        loadFilterS,
        emptyFilters,
        getAPIResponse
      );
    }

    setcontentOpenParent(true);
  };
  return (
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('remote_user') ? (
            <div className="warning-panel-remote">
              You are impersonating the user:{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).firstName}{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).lastName}. To
              exit, click the 'Exit User' link above.
            </div>
          ) : (
            ''
          )}
        </USBColumn>



        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('filesToCopy') ? (
            <div className="warning-panel-remote">
              Please select the Closing Binders requirement to paste the {(sessionStorage.getItem('filesToCopy')?.split(",")?.length)} selected documents.{' '}

              To cancel copying, click &nbsp;    

                            <USBButton
                                size="small"
                                variant='secondary'
                                handleClick={
                                    () => { sessionStorage.removeItem('filesToCopy'); sessionStorage.removeItem('pastedRequirementId'); sessionStorage.removeItem('pasted'); window.location.reload(); }
                                }
                                ariaLabel='Cancel Copy'
                            >
                                Cancel
                            </USBButton>

             
            </div>
          ) : (
            ''
          )}
        </USBColumn>

        



        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="portfolio"
            text="Reporting Dashboard"
            breadcrumbs={[
              {
                id: 1,
                text: 'My portfolio',
                href: '/ReportingDashboard',
              },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}>
          {ReportingModal(
            whichReport,
            reportResults,
            reportResultsFull,
            reportingModal,
            setReportingModal,
            hideReportingModal,
            fileType,
            setFileType,
            hideExcel,
            setHideExcel
          )}
          <div className="search-frame">
            <div className="search-section">
              <Dropdown
              className='global-search-dropdown'
                value={searchValue}
                options={searchData}
                onChange={(e) => searchDrop(e, 
                  setSearchValue,
                  reportResultsFull,
                  searchValue,
                  setReportResults,
                  setDashboardData,
                  dashboardData,
                  setTotalPages,
                  setTotalResults,
                  activeFilters,
                  sortByValue)}
                placeholder="Search by"
                ariaLabel="search by"
              />
              <input
                style={{ backgroundImage: `url(${searchIcon})` }}
                className="input-search-global"
                aria-label="search input"
                type="search"
                placeholder='Search'
                value={items?.search}
                onChange={(e) =>
                  handleSearchChange(
                    e.target.value,
                    reportResultsFull,
                    searchValue,
                    setReportResults,
                    setDashboardData,
                    dashboardData,
                    setTotalPages,
                    setTotalResults,
                    activeFilters,
                    sortByValue
                  )
                }
              />
            </div>
          </div>
        </USBColumn>
      </USBGrid>
      { !permissionDetails.includes("portfolio") && !loading ? (<>Access Denied</>): 
      (  <USBGrid addClasses="content-frame content-bg">
        <USBColumn largeSpan={16}>
          {loading ? <Loader /> : null}
          {refreshUI ? <Loader /> : null}
          {/* {/* Temp method to set session values in local dev */}
          {tempSessions === true ? (
            <button
              type="button"
              onClick={setSessionValues}
              style={{
                padding: '4px',
                color: '#ffffff',
                backgroundColor: '#0a41c5',
                border: '1px solid #0a41c5',
                fontFamily: "U.S. Bank Circular",
                borderRadius: '4px',
                marginLeft: '4px',
                position: 'fixed',
                zIndex: '100001',
              }}
            >
              Set Session
            </button>
          ) : (
            ''
          )}
          <h1 className="heading-h1">
            {assignedUserName.firstName === 'You' &&
            !sessionStorage.getItem('remote_user') &&
            !sessionStorage.getItem('view_as') ? (
              'My portfolio'
            ) : sessionStorage.getItem('remote_user') ? (
              <>
                <span className="span-bold">
                  {JSON.parse(sessionStorage.getItem('remote_user')).firstName}&nbsp;  
                  {JSON.parse(sessionStorage.getItem('remote_user')).lastName}
                </span>
                  &rsquo;s&nbsp;Portfolio
              </>
              ) : sessionStorage.getItem('view_as') ? (
              <>
                <span className="span-bold">
                  {JSON.parse(sessionStorage.getItem('view_as')).userName}
                </span>
                  &rsquo;s&nbsp;Portfolio
              </>
            ) : (
              <>
                <span className="span-bold">
                  {assignedUserName.firstName}
                  &nbsp;
                  {assignedUserName.lastName}
                </span>
                &rsquo;s&nbsp;Portfolio
              </>
            )}
          </h1>
          <div className="page-details-wrap">
            <div className="page-details-container">
              You&apos;ll see all of the open reporting requirements for
              your&nbsp;
              {(employeeFlag ||
                servicingUserRole === 'vendorsiteadmin' ||
                servicingUserRole === 'vendorcontributor') &&
              !JSON.parse(sessionStorage.getItem('remote_user'))
                ? 'customers'
                : 'organization'}
              &nbsp;listed here.&nbsp;
              {(employeeFlag ||
                servicingUserRole === 'vendorsiteadmin' ||
                servicingUserRole === 'vendorcontributor') &&
              !JSON.parse(sessionStorage.getItem('remote_user')) ? (
                <span className="page-details-inline">
                  Use the&nbsp;
                  <span className="span-bold">Parent company</span>
                  &nbsp;filter to view requirements for a specific organization.
                </span>
              ) : (
                ''
              )}
              <div
                className={`${
                  employeeFlag ||
                  servicingUserRole === 'vendorsiteadmin' ||
                  servicingUserRole === 'vendorcontributor'
                    ? 'page-detail'
                    : 'page-details-inline'
                }`}
              >
                Select a reporting requirement to view, upload or download
                documents.&nbsp;
                {(employeeFlag ||
                  servicingUserRole === 'vendorsiteadmin' ||
                  servicingUserRole === 'vendorcontributor') &&
                !JSON.parse(sessionStorage.getItem('remote_user')) ? (
                  <span className="page-details-inline">
                    You can also leave a new message or respond to a customer
                    message.
                  </span>
                ) : (
                  <span className="page-details-inline">
                    You can also respond to messages from your U.S. Bancorp Impact Finance team members
                    or leave a new message.
                  </span>
                )}
              </div>
            </div>

            {/* ============= Reporting section ============= */}
            {reportResultsFull.length !== 0 || totalResults > 0 ? (
              <div className="report-download portfolio">
                <div className="report-download-title">DOWNLOAD A REPORT</div>
                {downloadDataCheck(reportResultsFull) ? (
                  <button
                    type="button"
                    className="download-link"
                    onClick={() =>
                      showReportingModal(setReportingModal, 'pastdue')
                    }
                  >
                    All past due reporting (30 days or more)
                  </button>
                ) : (
                  ''
                )}
                {totalResults > 0 ? (
                  <button
                    type="button"
                    className="download-link"
                    onClick={() =>
                      showReportingModal(setReportingModal, 'portfolio')
                    }
                  >
                    My portfolio (all reporting)
                  </button>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            {/* ============= Reporting section ends ============= */}
          </div>
          <div className="filter-by">Filter by:</div>
          <div className="sorting-inputs">
            
            {(employeeFlag ||
              servicingUserRole === 'vendorsiteadmin' ||
              servicingUserRole === 'vendorcontributor' ||
              servicingUserRole === 'unlistedvendor') &&
            !JSON.parse(sessionStorage.getItem('remote_user')) ? (
              <div className={parentCompany.length <=1 ? "autofilterDropdowns disabledDropdown" : "autofilterDropdowns"}>
                <p className="heading">Parent Company</p>
                <div className="inputWithButton">
                  <button
                    type="button"
                    onClick={() => toggleContentParentCompany()}
                    className={parentCompany.length <=1 ? "dropbtn disabledDropdown" :"dropbtn"}
                    disabled={parentCompany.length <=1}
                  >
                    {' '}
                    &nbsp;{' '}
                    <img src={down} alt="down" className="down-image"></img>
                  </button>

                  <input
                    type="text"
                    className={parentCompany.length <=1 ? "dropdownelement disabledDropdown" :"dropdownelement"}
                    placeholder="Search by Company"
                    id="parentCompanyInput"
                    onChange={(e) =>
                      searchDropFilterParentCompany(e.target.value)
                    }
                    value={activeFilters.parentCompany}
                    disabled={parentCompany.length <=1}
                  />
                </div>
                {contentOpenParent ? (
                  <>
                    <div className="Autocomplete">
                      {!loading &&
                        filterParentCompany &&
                        filterParentCompany.length > 0 &&
                        filterParentCompany.map((item, index) => (
                          <div
                            key={index + 'p'}
                            className="dropdown-each"
                            role="button"
                            tabIndex="0"
                            onKeyDown={(event) =>
                              compNameKepress(event, item.value)
                            }
                            onClick={() =>
                              loadByEntityName(
                                item.value,
                                'parentCompany',
                                activeFilters,
                                setActiveFilters,
                                reportResultsFull,
                                setReportResults,
                                setDashboardData,
                                dashboardData,
                                sortByValue,
                                setTotalPages,
                                setTotalResults,
                                searchValue,
                                loadFilterS,
                                emptyFilters,
                                getAPIResponse
                              )
                            }
                          >
                            {/* {item.content} */}
                            {item.value}
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            <div className={reportingEntity.length <=1 ? "autofilterDropdowns disabledDropdown" :"autofilterDropdowns"}>
              <p className="heading">Legal Entity</p>
              <div className="inputWithButton">
                <button
                  type="button"
                  onClick={() => toggleContent()}
                  className={reportingEntity.length <=1 ? "dropbtn disabledDropdown" :"dropbtn"}
                  disabled={reportingEntity.length <=1}
                >
                  {' '}
                  &nbsp;{' '}
                  <img src={down} alt="down" className="down-image"></img>
                </button>

                <input
                  type="text"
                  className={reportingEntity.length <=1 ? "dropdownelement disabledDropdown" :"dropdownelement"}
                  placeholder="Search by Entity"
                  id="legalEntityInput"
                  onChange={(e) => searchDropFilter(e.target.value)}
                  value={activeFilters.reportingEntity}
                  disabled={reportingEntity.length <=1}
                />
              </div>
              {contentOpen ? (
                <>
                  <div className="Autocomplete">
                    {!refreshUI && !loading && 
                      filterReportingEntity &&
                      filterReportingEntity.length > 0 &&
                      filterReportingEntity.map((item, index) => (
                        <div
                          key={index + 'l'}
                          className="dropdown-each"
                          role="button"
                          tabIndex="0"
                          onKeyDown={(event) =>
                            compNameKepress(event, item.value)
                          }
                          onClick={() =>
                            loadByEntityName(
                              item.value,
                              'reportingEntity',
                              activeFilters,
                              setActiveFilters,
                              reportResultsFull,
                              setReportResults,
                              setDashboardData,
                              dashboardData,
                              sortByValue,
                              setTotalPages,
                              setTotalResults,
                              searchValue,
                              loadFilterS,
                              emptyFilters,
                              getAPIResponse
                            )
                          }
                        >
                          {/* {item.content} */}
                          {item.value}
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            { (
              <div className={dealName.length <=1 ? "autofilterDropdowns disabledDropdown" : "autofilterDropdowns"}>
                <p className="heading">Deal Name</p>
                <div className="inputWithButton">
                  <button
                    type="button"
                    onClick={() => toggleContentDealName()}
                    className={dealName.length <=1 ? "dropbtn disabledDropdown" : "dropbtn"}
                    disabled={dealName.length <= 1}
                  >
                    {' '}
                    &nbsp;{' '}
                    <img src={down} alt="down" className="down-image"></img>
                  </button>

                  <input
                    type="text"
                    className={dealName.length <=1 ? "dropdownelement disabledDropdown" : "dropdownelement"}
                    placeholder="Search by DealName"
                    id="dealNameInput"
                    onChange={(e) => searchDropFilterDeal(e.target.value)}
                    value={activeFilters.dealName}
                    disabled={dealName.length <= 1}
                  />
                </div>
                {contentOpenDeal ? (
                  <>
                    <div className="Autocomplete">
                      {!loading &&
                        filterDealName &&
                        filterDealName.length > 0 &&
                        filterDealName.map((item, index) => (
                          <div
                            key={index + 'd'}
                            className="dropdown-each"
                            role="button"
                            tabIndex="0"
                            onKeyDown={(event) =>
                              compNameKepress(event, item.value)
                            }
                            onClick={() =>
                              loadByEntityName(
                                item.value,
                                'dealName',
                                activeFilters,
                                setActiveFilters,
                                reportResultsFull,
                                setReportResults,
                                setDashboardData,
                                dashboardData,
                                sortByValue,
                                setTotalPages,
                                setTotalResults,
                                searchValue,
                                loadFilterS,
                                emptyFilters,
                                getAPIResponse
                              )
                            }
                          >
                            {/* {item.content} */}
                            {item.value}
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            )}
            {
            employeeFlag &&
            !JSON.parse(sessionStorage.getItem('remote_user')) ? (
              <div className={syndicationFund.length <=1 ? "autofilterDropdowns disabledDropdown" :"autofilterDropdowns"}>
                <p className="heading">Fund Name</p>
                <div className="inputWithButton">
                  <button
                    type="button"
                    onClick={() => toggleContentFund()}
                    className={syndicationFund.length <=1 ? "dropbtn disabledDropdown" : "dropbtn"}
                    disabled={syndicationFund.length <=1}
                  >
                    {' '}
                    &nbsp;{' '}
                    <img src={down} alt="down" className="down-image"></img>
                  </button>

                  <input
                    type="text"
                    className={syndicationFund.length <=1 ? "dropdownelement disabledDropdown" :"dropdownelement"}
                    placeholder="Search by FundName"
                    id="fundNameInput"
                    onChange={(e) => searchDropFilterFund(e.target.value)}
                    value={activeFilters.syndicationFundName}
                    disabled={syndicationFund.length <= 1}
                  />
                </div>
                {contentOpenFund ? (
                  <>
                    <div className="Autocomplete">
                      {!loading &&
                        filterFundName &&
                        filterFundName.length > 0 &&
                        filterFundName.map((item, index) => (
                          <div
                            key={index + 'f'}
                            className="dropdown-each"
                            role="button"
                            tabIndex="0"
                            onKeyDown={(event) =>
                              compNameKepress(event, item.value)
                            }
                            onClick={() =>
                              loadByEntityName(
                                item.value,
                                'syndicationFundName',
                                activeFilters,
                                setActiveFilters,
                                reportResultsFull,
                                setReportResults,
                                setDashboardData,
                                dashboardData,
                                sortByValue,
                                setTotalPages,
                                setTotalResults,
                                searchValue,
                                loadFilterS,
                                emptyFilters,
                                getAPIResponse
                              )
                            }
                          >
                            {/* {item.content} */}
                            {item.value}
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}

           
            {employeeFlag && showSyndication ? (
             
              
            <div className={syndicationFund.length <=1 ? "filterDropdowns disabledDropdown" :"filterDropdowns"}>
                <USBSelect
                  
                  id="fundname"
                  inputName="fundname"
                  initValue={activeFilters.syndicationFundName || undefined}
                  labelText="Fund name"
                  optionsArray={syndicationFund}
                  handleChange={(e) =>
                    filterData(
                      e,
                      'syndicationFundName',
                      setStatusData,
                      activeFilters,
                      setActiveFilters,
                      reportResultsFull,
                      setReportResults,
                      setDashboardData,
                      dashboardData,
                      sortByValue,
                      setTotalPages,
                      setTotalResults,
                      searchValue,
                      loadFilterS,
                      emptyFilters,
                      getAPIResponse,
                      setRefreshUI
                    )
                  }
                  // ariaLabel="fund name"
                />
              </div>
            ) : (
              ''
            )}
            <div className="filterDropdowns">
              {!loading && <USBSelect
                id="documenttype"
                name="documenttype"
                initValue={activeFilters.requirementName}
                labelText="Document type"
                optionsArray={requirementName}
                handleChange={(e) =>
                  filterData(
                    e,
                    'requirementName',
                    setStatusData,
                    activeFilters,
                    setActiveFilters,
                    reportResultsFull,
                    setReportResults,
                    setDashboardData,
                    dashboardData,
                    sortByValue,
                    setTotalPages,
                    setTotalResults,
                    searchValue,
                    loadFilterS,
                    emptyFilters,
                    getAPIResponse,
                    setRefreshUI
                  )
                }
                // ariaLabel="document type"
              /> }
            </div>
            <div className="filterDropdowns">
              <USBSelect
                id="status"
                name="status"
                initValue={activeFilters.status || undefined}
                labelText="Status"
                optionsArray={statusDrop}
                handleChange={(e) =>
                  filterData(
                    e,
                    'status',
                    setStatusData,
                    activeFilters,
                    setActiveFilters,
                    reportResultsFull,
                    setReportResults,
                    setDashboardData,
                    dashboardData,
                    sortByValue,
                    setTotalPages,
                    setTotalResults,
                    searchValue,
                    loadFilterS,
                    emptyFilters,
                    getAPIResponse,
                    setRefreshUI
                  )
                }
                // ariaLabel="status"
              />
            </div>
            {employeeFlag && !sessionStorage.getItem('remote_user') ? (
              <div className="filterDropdowns">
                <USBSelect
                  id="follow"
                  name="follow"
                  initValue={activeFilters.following || undefined}
                  labelText="Following"
                  optionsArray={followCheck}
                  handleChange={(e) =>
                    filterData(
                      e,
                      'followCheck',
                      setStatusData,
                      activeFilters,
                      setActiveFilters,
                      reportResultsFull,
                      setReportResults,
                      setDashboardData,
                      dashboardData,
                      sortByValue,
                      setTotalPages,
                      setTotalResults,
                      searchValue,
                      loadFilterS,
                      emptyFilters,
                      getAPIResponse,
                      setRefreshUI
                    )
                  }
                  // ariaLabel="status"
                />
              </div>
            ) : (
              ''
            )}
          
            {employeeFlag ||
            servicingUserRole === 'vendorsiteadmin' ||
            servicingUserRole === 'vendorcontributor' ||
            servicingUserRole === 'unlistedvendor' ||
            servicingUserRole === 'siteadmin' ||
            servicingUserRole === 'contributor' ||
            servicingUserRole === 'customer' ? (
              <div className="datefilterDropdowns">
                { !loading && <USBDatePicker
                  id="asofdate"
                  inputName="asofdate"
                  initValue={activeFilters.asOfDate || undefined}
                  labelText2="As of Date"
                  statusUpdateCallback={(e) => {

                    loadByEntityName(
                      activeFilters.parentCompany,
                      'parentCompany',
                      activeFilters,
                      setActiveFilters,
                      reportResultsFull,
                      setReportResults,
                      setDashboardData,
                      dashboardData,
                      sortByValue,
                      setTotalPages,
                      setTotalResults,
                      searchValue,
                      loadFilterS,
                      emptyFilters,
                      getAPIResponse,
                      false
                    );


                    if(e.event=="onChange" ||e.event=="dynamicValue")
                    filterData(
                      e,
                      'asOfDate',
                      setStatusData,
                      activeFilters,
                      setActiveFilters,
                      reportResultsFull,
                      setReportResults,
                      setDashboardData,
                      dashboardData,
                      sortByValue,
                      setTotalPages,
                      setTotalResults,
                      searchValue,
                      loadFilterS,
                      emptyFilters,
                      getAPIResponse,
                      setRefreshUI
                    );
                  }
                  }
                  settings={{
                    dateInput: {
                      ...defaultSettingsDateInput1,
                    },
                    calendar: {
                      id: 'datepicker',
                      ...defaultCalendarSettings,
                      ...defaultSettingsCalendar1,
                      occurrence: 'both',
                      numberOfYearsToShow: '10',
                      labelText: 'As of Date',
                    },
                  }}
                  content={{
                    ...defaultContent1,
                    labelText: 'Start date',
                    inputName: 'asofdate',
                  }}
                /> }
              </div>
            ) : (
              ''
            )}
            {(employeeFlag ||
            servicingUserRole === 'vendorsiteadmin' ||
            servicingUserRole === 'vendorcontributor' ||
            servicingUserRole === 'unlistedvendor' ||
            servicingUserRole === 'siteadmin' ||
            servicingUserRole === 'contributor' ||
            servicingUserRole === 'customer') && includesCBDocs ? (
              <div className="datefilterDropdowns">
                { !loading && <USBDatePicker
                  id="cbDate"
                  inputName="cbDate"
                  initValue={activeFilters.cbDate || undefined}
                  labelText2="Closing Binder Date"
                  statusUpdateCallback={(e) => {

                    loadByEntityName(
                      activeFilters.parentCompany,
                      'parentCompany',
                      activeFilters,
                      setActiveFilters,
                      reportResultsFull,
                      setReportResults,
                      setDashboardData,
                      dashboardData,
                      sortByValue,
                      setTotalPages,
                      setTotalResults,
                      searchValue,
                      loadFilterS,
                      emptyFilters,
                      getAPIResponse,
                      false
                    );


                    if(e.event=="onChange" ||e.event=="dynamicValue")
                    filterData(
                      e,
                      'cbDate',
                      setStatusData,
                      activeFilters,
                      setActiveFilters,
                      reportResultsFull,
                      setReportResults,
                      setDashboardData,
                      dashboardData,
                      sortByValue,
                      setTotalPages,
                      setTotalResults,
                      searchValue,
                      loadFilterS,
                      emptyFilters,
                      getAPIResponse,
                      setRefreshUI
                    );
                  }
                  }
                  settings={{
                    dateInput: {
                      ...defaultSettingsDateInput1,
                      initValue : activeFilters?.cbDate || undefined,
                    },
                    calendar: {
                      id: 'datepicker',
                      ...defaultCalendarSettings,
                      ...defaultSettingsCalendar1,
                      occurrence: 'both',
                      numberOfYearsToShow: '10',
                      labelText: 'Closing Binder Date',
                    },
                  }}
                  content={{
                    dateInput: {
                      calendarButtonAriaLabel: 'Choose date',
                      helperText: 'mm/dd/yyyy',
                      helperTextOptional: 'Optional',
                      labelText: 'Closing Binder Date',
                      errorMessages: {
                        default: 'Please enter a valid date.',
                        202: 'Date should be in the past.',
                        203: 'Date should be in the future.',
                        221: 'Enter no more than 10 characters.',
                        213: 'Enter numbers only.',
                        224: '(Optional field)',
                        228: "That date isn't available. Please try again.",
                      },
                    },
                  }}
                /> }
              </div>
            ) : (
              ''
            )}

            {(employeeFlag ||
              servicingUserRole === 'vendorsiteadmin' ||
              servicingUserRole === 'vendorcontributor' ||
              servicingUserRole === 'unlistedvendor' ||
              servicingUserRole === 'siteadmin' ||
              servicingUserRole === 'contributor' ||
              servicingUserRole === 'customer') &&
            activeFilters.status !== 'Not Submitted' ? (
              <>
                <div className="datefilterDropdowns">
                  { !loading && <USBDatePicker
                    id="submitteddate"
                    inputName="submitteddate"
                    initValue={activeFilters?.submittedDate?.length==10 ? activeFilters?.submittedDate : undefined}
                    labelText="Received Date"
                    statusUpdateCallback={(e) => 
                      {
                      if(e.event=="onChange" ||e.event=="dynamicValue")
                      filterData(
                        e,
                        'submittedDate',
                        setStatusData,
                        activeFilters,
                        setActiveFilters,
                        reportResultsFull,
                        setReportResults,
                        setDashboardData,
                        dashboardData,
                        sortByValue,
                        setTotalPages,
                        setTotalResults,
                        searchValue,
                        loadFilterS,
                        emptyFilters,
                        getAPIResponse,
                        setRefreshUI
                      )
                    }
                  }
                    settings={{
                      dateInput: {
                        ...defaultSettingsDateInput,
                      },
                      calendar: {
                        id: 'datepicker',
                        ...defaultCalendarSettings,
                        ...defaultSettingsCalendar,
                        occurrence: 'past',
                        numberOfYearsToShow: '10',
                        labelText: 'Received Date',
                      },
                    }}
                    content={{
                      ...defaultContent,
                      labelText: 'Start date',
                      inputName: 'submitteddate',
                    }}
                  /> }
                </div>
              </>
            ) : (
              ''
            )}
            </div>
            <div className="clearFilter">
              <USBButton
                id="clearfilter"
                name="clearfilter"
                variant="utility"
                className="clear-filter"
                handleClick={() => {
                  setStatusData((prev)=>{
                    if (sessionStorage.getItem('userrole') === 'employee' && !JSON.parse(sessionStorage.getItem('remote_user'))){
                      return 'Submitted'
                    } else {
                      return 'Not Submitted'
                    }
                  })
                  clearFilter(sessionStorage.getItem('assignedUser'));
                  // window.location.href = '/ReportingDashboard';
                }}
              >
                Clear filters
              </USBButton>
            </div>
            <div className="filterDropdowns">
              <Dropdown
                value={sortByValue}
                options={sortBy}
                placeholder="Sort by"
                onChange={(e) =>
                  sortFilteredData(
                    e,
                    reportResults,
                    setReportResults,
                    setSortByValue,
                    setTotalPages,
                    setTotalResults
                  )
                }
                ariaLabel="sort by"
              />
            </div>
          
          <div className="totalresultsno">
            {totalResults}
            &nbsp; results
          </div>
          <div className="page-content">
            {activeFilters?.status != 'Not Submitted' ||
            activeFilters?.status == 'Status'
              ? !loading && (
                  <USBTable
                    currentPageIndex={currentPage}
                    handlePageChange={(e)=> {
                      setTimeout(() => {
                        setCurrentPageTemp(e)
                      }, 500);
                    }
                    }
                    addClasses="pagination-container"
                    displayVariant="auto"
                    useBorder="true"
                    paginationOptions={rangeArray}
                    handleItemCountChange={(itemCount) =>
                      updateRangeArray(itemCount)
                    }
                    paginationLabels={{
                      previousButton: 'Previous page',
                      nextButton: 'Next page',
                      unit: 'Page',
                      preposition: 'of',
                      rowDropdown: 'Per page',
                    }}
                    paginationPosition="both"
                    columnData={
                      !sessionStorage.getItem('remote_user') && tempUserRole === 'employee'
                        ? [
                            {
                              headerName: 'DUE DATE',
                              fieldFor: 'first',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DEAL / FUND NAME',
                              fieldFor: 'second',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'LEGAL ENTITY',
                              fieldFor: 'third',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DOCUMENT TYPE',
                              fieldFor: 'fourth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: includesCBDocs ? 'AS OF DATE / CLOSING BINDER DATE' : 'AS OF DATE',
                              fieldFor: 'fifth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'RECEIVED DATE',
                              fieldFor: 'sixth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'STATUS',
                              fieldFor: 'seventh',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'FOLLOW',
                              fieldFor: 'eighth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'ACTION',
                              fieldFor: 'ninth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            }
                          ]
                        : [
                            {
                              headerName: 'DUE DATE',
                              fieldFor: 'first',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DEAL / FUND NAME',
                              fieldFor: 'second',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'LEGAL ENTITY',
                              fieldFor: 'third',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DOCUMENT TYPE',
                              fieldFor: 'fourth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: includesCBDocs ? 'AS OF DATE / CLOSING BINDER DATE' :'AS OF DATE',
                              fieldFor: 'fifth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'RECEIVED DATE',
                              fieldFor: 'sixth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'STATUS',
                              fieldFor: 'seventh',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'ACTION',
                              fieldFor: 'eighth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            }
                          ]
                    }
                    tableData={
                      reportResults.length != 0
                        ? paginationtableData(
                            reportResults,
                            activeFilters.status,
                            tempUserRole,
                            setLoading,setActionsDrop,setCurrentPage,currentPageTemp
                          )
                        : !sessionStorage.getItem('remote_user') && tempUserRole === 'employee' ? [ 
                            {
                              first: '-',
                              second: '-',
                              third: '-',
                              fourth: '-',
                              fifth: '-',
                              sixth: '-',
                              seventh: '-',
                              eighth: '-',
                              ninth: '-'
                            },
                          ] : [{
                            first: '-',
                            second: '-',
                            third: '-',
                            fourth: '-',
                            fifth: '-',
                            sixth: '-',
                            seventh: '-',
                            eighth: '-',
                          },
                        ]
                    }
                  />
                )
              : !loading && (
                  <USBTable
                  currentPageIndex={currentPage}
                    handlePageChange={(e)=> {
                      setTimeout(() => {
                        setCurrentPageTemp(e)
                      }, 100);
                    }
                    }
                    addClasses="pagination-container"
                    displayVariant="auto"
                    useBorder="true"
                    paginationOptions={rangeArray}
                    handleItemCountChange={(itemCount) =>
                      updateRangeArray(itemCount)
                    }
                    paginationLabels={{
                      previousButton: 'Previous page',
                      nextButton: 'Next page',
                      unit: 'Page',
                      preposition: 'of',
                      rowDropdown: 'Per page',
                    }}
                    paginationPosition="both"
                    columnData={
                      sessionStorage.getItem('userrole') === 'employee' &&
                      !sessionStorage.getItem('remote_user')
                        ? [
                            {
                              headerName: 'DUE DATE',
                              fieldFor: 'first',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DEAL / FUND NAME',
                              fieldFor: 'second',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'LEGAL ENTITY',
                              fieldFor: 'third',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DOCUMENT TYPE',
                              fieldFor: 'fourth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: includesCBDocs ? 'AS OF DATE / CLOSING BINDER DATE' :'AS OF DATE',
                              fieldFor: 'fifth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'STATUS',
                              fieldFor: 'sixth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'FOLLOW',
                              fieldFor: 'seventh',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'ACTION',
                              fieldFor: 'eighth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                          ]
                        : [
                            {
                              headerName: 'DUE DATE',
                              fieldFor: 'first',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DEAL / FUND NAME',
                              fieldFor: 'second',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'LEGAL ENTITY',
                              fieldFor: 'third',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DOCUMENT TYPE',
                              fieldFor: 'fourth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: includesCBDocs ? 'AS OF DATE / CLOSING BINDER DATE' :'AS OF DATE',
                              fieldFor: 'fifth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'STATUS',
                              fieldFor: 'sixth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'ACTION',
                              fieldFor: 'seventh',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                          ]
                    }
                    tableData={
                      reportResults.length != 0
                        ? paginationtableData(
                            reportResults,
                            activeFilters.status,
                            tempUserRole,setLoading,setActionsDrop,setCurrentPage,currentPageTemp
                          )
                        : !sessionStorage.getItem('remote_user') && tempUserRole === 'employee' ? [
                            {
                              first: '-',
                              second: '-',
                              third: '-',
                              fourth: '-',
                              fifth: '-',
                              sixth: '-',
                              seventh: '-',
                              eighth: '-',
                            },
                          ] : [
                            {
                              first: '-',
                              second: '-',
                              third: '-',
                              fourth: '-',
                              fifth: '-',
                              sixth: '-',
                              seventh: '-',
                            },
                          ]
                    }
                  />
                )}
          </div>
        </USBColumn>
      </USBGrid>)
      }
    </>
  );
};

export default ReportingDashboard;